/** @jsxImportSource @emotion/react */
import { useReducer, useEffect, useContext, useState, useRef } from 'react'
import { useRouter } from 'next/router'
import { AppContext } from '../../components'
import { Card, Input, Space, Form, Button, Modal } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import Image from 'next/image'
import { CURRENT_SEMESTER, CURRENT_YEAR, ROLES } from '../../utils/config'
import { css } from '@emotion/react'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
}

const centerStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #2C8986;
`
const cardStyle = css`
  text-aligh: left;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
  min-width: 350px;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
`
const inputStyle = css`
  border-radius: 5px;
  height: 32px;
  width: 250px;
  margin-bottom: 10px;
  border: solid 1px #e0e0e0;
  padding: 0 5px 0 5px;
`
const imageStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
`;

const formInstructionStyle = css`
  color: #000000BB;
  text-align: center;
`
const errorStyle = css`
  float: left;
  padding: 15px 0px 5px 0px;
  color: red;
`
const submitButtonContainerStyle = css`
  display: flex !important;;
  padding: 15px 5px 0px 0px;
  justify-content: flex-end;
`

const Login = () => {
  const [isError, setIsError] = useState(false)
  const [error2FA, setError2FA] = useState('')
  const [username, setUsername] = useState('')
  const [pass, setPass] = useState('')
  const [form2FA] = Form.useForm()
  const inputcode1 = useRef(null)
  const inputcode2 = useRef(null)
  const inputcode3 = useRef(null)
  const inputcode4 = useRef(null)
  const inputcode5 = useRef(null)
  const inputcode6 = useRef(null)
  const [tmpUser, setTmpUser] = useState(null)
  const [state, dispatch] = useContext(AppContext)
  const router = useRouter()

  useEffect(() => {
    // console.log("useEffect", state);
    // console.log(router.query?.system);
  }, [router.query?.system])

  const onFinish = async () => {
    if (username?.length === 0 || pass?.length === 0) return
    try {
      const res = await fetch('https://cct.thaieduforall.org/auth/login', {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          password: pass,
          application: 'cct-monitor',
        }),
        method: 'POST',
      })
      if (res.status !== 200) return setIsError('ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง')
      setIsError(false)
      setError2FA("")
      form2FA.resetFields()
      const user = await res.json()
      if (user.Secret2FA) {
        setTmpUser(user)

        const {
          user: { roles: userRoles },
        } = user

        if (userRoles?.includes('staff_tu')) router.push(`${ROLES['staff_tu'][0]}/cct/${CURRENT_YEAR}/${CURRENT_SEMESTER}`)

        inputcode1.current.focus()

      }
      else setIsError('ไม่พบ 2FA')
    } catch (e) {
      console.log(e)
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const handleOnSubmit2FA = async () => {
    setError2FA(``)
    const code1 = inputcode1?.current?.input?.value
    const code2 = inputcode2?.current?.input?.value
    const code3 = inputcode3?.current?.input?.value
    const code4 = inputcode4?.current?.input?.value
    const code5 = inputcode5?.current?.input?.value
    const code6 = inputcode6?.current?.input?.value
    const { Secret2FA, token } = tmpUser
    const allCode = `${code1}${code2}${code3}${code4}${code5}${code6}`
    if (allCode.length !== 6) return;

    const res = await fetch('https://api-auth.inforvation.systems/verify2FA', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${token}`
      },
      body: JSON.stringify({
        token: allCode,
        secret: Secret2FA,
      }),
      method: 'POST',
    })
    const response = await res.json()
    if (res.status !== 200) {
      const { message } = response
      setError2FA(`${message || ""}`)
      return;
    } else {
      setError2FA(``)
      sessionStorage.setItem('t', token)
      dispatch({
        type: 'set_user',
        value: tmpUser,
      })
    }
  }

  return (
    <div css={centerStyle}>
      <div css={cardStyle}>
        <label htmlFor="username">ชื่อผู้ใช้งาน</label>
        <input css={inputStyle} name="username" onChange={(e) => setUsername(e?.target?.value)}></input>
        <label htmlFor="password">รหัสผ่าน</label>
        <input css={inputStyle} name="password" type="password" onChange={(e) => setPass(e?.target?.value)}></input>
        <Button type="primary" size="large" styles={{ width: 150 }} onClick={onFinish}>
          เข้าสู่ระบบ
        </Button>

        {isError && (
          <div css={errorStyle}>
            {isError}
          </div>
        )}
      </div>

      <Modal
        title={`Welcome, ${(tmpUser?.user?.profile?.title) || ''}${(tmpUser?.user?.profile?.firstname) || ''} ${(tmpUser?.user?.profile?.lastname) || ''}`}
        centered
        open={tmpUser !== null}
        footer={null}
        width={600}
        onCancel={() => setTmpUser(null)}
        forceRender
      >
        <div css={cardStyle}>
          <Image src="/images/google_authen_icon.png" height={100} width={100} />
          <div css={formInstructionStyle}>Enter 6 digit code from Google Authenticator.</div>
          <div css={css`width: 350px; margin: 10px auto 0 auto;`}>
            <Space>
              <Input
                autoFocus
                onFocus={(e) => e.target.select()}
                onKeyUp={(e) => {
                  (e.target.value.length > 0) && inputcode2.current.focus();
                }}
                ref={inputcode1}
                maxLength={1}
                style={{ textAlign: 'center', height: 50, width: 50 }}
              />
              <Input
                onFocus={(e) => e.target.select()}
                onKeyUp={(e) => {
                  if (e.key === "Backspace") return inputcode1.current.focus();
                  if (e.target.value.length > 0) inputcode3.current.focus();
                }}
                ref={inputcode2}
                maxLength={1}
                style={{ textAlign: 'center', height: 50, width: 50 }}
              />
              <Input
                onFocus={(e) => e.target.select()}
                onKeyUp={(e) => {
                  if (e.key === "Backspace") return inputcode2.current.focus();
                  if (e.target.value.length > 0) inputcode4.current.focus();
                }}
                ref={inputcode3}
                maxLength={1}
                style={{ textAlign: 'center', height: 50, width: 50 }}
              />
              <Input
                onFocus={(e) => e.target.select()}
                onKeyUp={(e) => {
                  if (e.key === "Backspace") return inputcode3.current.focus();
                  if (e.target.value.length > 0) inputcode5.current.focus();
                }}
                ref={inputcode4}
                maxLength={1}
                style={{ textAlign: 'center', height: 50, width: 50 }}
              />
              <Input
                onFocus={(e) => e.target.select()}
                onKeyUp={(e) => {
                  if (e.key === "Backspace") return inputcode4.current.focus();
                  if (e.target.value.length > 0) inputcode6.current.focus();
                }}
                ref={inputcode5}
                maxLength={1}
                style={{ textAlign: 'center', height: 50, width: 50 }}
              />
              <Input
                onFocus={(e) => e.target.select()}
                onKeyUp={(e) => {
                  if (e.key === "Backspace") return inputcode5.current.focus();
                  if (e.target.value.length > 0) form2FA.submit();
                }}
                ref={inputcode6}
                maxLength={1}
                onChange={handleOnSubmit2FA}
                style={{ textAlign: 'center', height: 50, width: 50 }}
              />
            </Space>
          </div>
          <div css={errorStyle}>
            {error2FA.length > 0 &&
              <>
                <InfoCircleOutlined />
                {` ${error2FA}`}
              </>
            }
          </div>
          <div css={submitButtonContainerStyle}>
            <Button onClick={handleOnSubmit2FA} size="large" type="primary" ghost>Submit</Button>
          </div>
        </div>
      </Modal >
    </div >
  )
}

export default Login
