const duration = 60 * 1 // 1 min

const YEAR_SEMESTER = {
  '1/2567': 'เทอม 1/2567',
  '2/2566': 'เทอม 2/2566',
  '1/2566': 'เทอม 1/2566',
  '2/2565': 'เทอม 2/2565',
  '1/2565': 'เทอม 1/2565',
  '2/2564': 'เทอม 2/2564',
  '1/2564': 'เทอม 1/2564',
  // '2/2563': 'เทอม 2/2563',
  // '1/2563': 'เทอม 1/2563',
}

const MONTH = {
  '00': 'ทั้งหมด',
  '01': 'มกราคม',
  '02': 'กุมภาพันธ์',
  '03': 'มีนาคม',
  '04': 'เมษายน',
  '05': 'พฤษภาคม',
  '06': 'มิถุนายน',
  '07': 'กรกฎาคม',
  '08': 'สิงหาคม',
  '09': 'กันยายน',
  '10': 'ตุลาคม',
  '11': 'พฤศจิกายน',
  '12': 'ธันวาคม',
}

const CYS = Object.keys(YEAR_SEMESTER)[0].split('/')

const CURRENT_YEAR = Number(CYS[1]) - 543
const CURRENT_SEMESTER = Number(CYS[0])

const SYSTEMS = [
  { system: 'cct', title: 'สพฐ', image: '/images/obec.png' },
  { system: 'cct-dla', title: 'อปท.', image: '/images/dla.png' },
  { system: 'cct-bpp', title: 'ตชด.', image: '/images/bpp.png' },
  { system: 'cct-opec', title: 'สช.', image: '/images/opec.png' },
  { system: 'cct-onab', title: 'พศ.', image: '/images/onab.png' },
  { system: 'cct-bkk', title: 'กทม.', image: '/images/bkk.png' },
]

const OBEC_OPEN_CLASS = {
  '20241start': '2024-05-16',
  '20241end': '2024-10-07',
  '20232start': '2023-11-01',
  '20232end': '2024-04-01',
  '20231start': '2023-05-16',
  '20231end': '2023-10-10',
  '20201start': '2020-07-01',
  '20201end': '2020-11-13',
  '20202start': '2020-12-01',
  '20202end': '2021-04-09',
  '20211start': '2021-06-14',
  '20211end': '2021-10-11',
  '20212start': '2021-11-01',
  '20212end': '2022-03-31',
  '20221start': '2022-05-17',
  '20221end': '2022-10-12',
  '20222start': '2022-11-01',
  '20222end': '2023-03-31',
}

const ROLES = {
  eef: [],
  staff: ['/progress/checkimg', '/result/student'],
  staff_tu: ['/check/checkimg'],
}

const EXCLUDE_SIDEBAR_MENU = [
  'cct_/progress/spending',
  'cct-dla_/progress/spending',
  'cct-bpp_/progress/spending',
  'cct-opec_/progress/spending',
  'cct-bkk_/progress/spending',
  'cct-opec_/progress/form06',
  'cct-opec_/progress/form001',
  'cct-opec_/progress/form002',
  'cct-opec_/progress/support',
  'cct-onab_/progress/report',
  'cct-onab_/progress/support',
  'cct-opec_/track/form06',
  'cct-opec_/track/form001',
  'cct-opec_/track/form002',
  'cct-dla_/progress/risk',
  'cct-bpp_/progress/risk',
  'cct-onab_/progress/risk',
  'cct-opec_/progress/risk',
  'cct-bkk_/progress/form06',
  'cct-bkk_/progress/form001',
  'cct-bkk_/progress/form002',
  // 'cct-bkk_/progress/report',
  // 'cct-bkk_/progress/attendance',
  // 'cct-bkk_/progress/wh',
  // 'cct-bkk_/progress/covid19',
  // 'cct-bkk_/progress/refund',
  'cct-bkk_/progress/support',
  'cct-bkk_/progress/risk',
  'cct-bkk_/progress/paid',
  'cct-dla_/progress/senior',
  'cct-bpp_/progress/senior',
  'cct-opec_/progress/senior',
  'cct-onab_/progress/senior',
  'cct-bkk_/progress/senior',
]

const EXCLUDE_MENU_YEAR = [
  // cct
  '/progress/form01/cct/2023/2',
  '/progress/form01/cct/2021/2',
  '/progress/form01/cct/2022/2',
  '/progress/form05/cct/2024/1/normal',
  '/progress/form05/cct/2022/2/normal',
  '/progress/form05/cct/2023/2/normal',
  '/progress/form06/cct/2021/1',
  '/progress/form06/cct/2021/2',
  '/progress/form06/cct/2022/1',
  '/progress/form06/cct/2022/2',
  '/progress/report/cct/2020/1',
  '/progress/report/cct/2020/2',
  '/progress/form001/cct/2020/2',
  '/progress/form001/cct/2021/1',
  '/progress/form001/cct/2021/2',
  '/progress/form001/cct/2022/1',
  '/progress/form001/cct/2022/2',
  '/progress/form002/cct/2020/2',
  '/progress/form002/cct/2021/1',
  '/progress/form002/cct/2021/2',
  '/progress/form002/cct/2022/1',
  '/progress/form002/cct/2022/2',
  '/progress/covid19/cct/2020/1',
  '/progress/covid19/cct/2020/2',
  '/progress/covid19/cct/2021/1',
  '/progress/covid19/cct/2022/1',
  '/progress/support/cct/2020/1',
  '/progress/support/cct/2021/1',
  '/progress/support/cct/2022/1',
  '/progress/support/cct/2022/2',
  '/progress/risk/cct/2020/1',
  '/progress/risk/cct/2020/2',
  '/progress/risk/cct/2021/1',
  '/progress/risk/cct/2021/2',
  '/progress/risk/cct/2022/2',
  '/check/checkimg/cct/2021/2',
  '/check/checkimg/cct/2022/2',
  '/result/form01/cct/2021/2',
  '/result/form01/cct/2022/2',
  '/result/form01/cct/2023/2',
  '/result/form05/cct/2022/2',
  '/result/eef/cct/2021/2',
  '/result/eef/cct/2022/2',
  '/track/form01/cct/2021/2',
  '/track/form01/cct/2022/2',
  '/track/form05/cct/2022/2',
  '/track/form06/cct/2021/1',
  '/track/form06/cct/2021/2',
  '/track/form06/cct/2022/1',
  '/track/form06/cct/2022/2',
  '/track/form001/cct/2020/2',
  '/track/form001/cct/2021/1',
  '/track/form001/cct/2021/2',
  '/track/form001/cct/2022/1',
  '/track/form001/cct/2022/2',
  '/track/form002/cct/2020/2',
  '/track/form002/cct/2021/1',
  '/track/form002/cct/2021/2',
  '/track/form002/cct/2022/1',
  '/track/form002/cct/2022/2',
  // dla
  '/progress/form01/cct-dla/2023/2',
  '/progress/form01/cct-dla/2021/2',
  '/progress/form01/cct-dla/2022/2',
  '/progress/form05/cct-dla/2024/1/normal',
  '/progress/form05/cct-dla/2022/2/normal',
  '/progress/form05/cct-dla/2023/2/normal',
  '/progress/form06/cct-dla/2021/1',
  '/progress/form06/cct-dla/2021/2',
  '/progress/form06/cct-dla/2022/1',
  '/progress/form06/cct-dla/2022/2',
  '/progress/report/cct-dla/2020/1',
  '/progress/report/cct-dla/2020/2',
  '/progress/form001/cct-dla/2020/2',
  '/progress/form001/cct-dla/2021/1',
  '/progress/form001/cct-dla/2021/2',
  '/progress/form001/cct-dla/2022/1',
  '/progress/form001/cct-dla/2022/2',
  '/progress/form002/cct-dla/2020/2',
  '/progress/form002/cct-dla/2021/1',
  '/progress/form002/cct-dla/2021/2',
  '/progress/form002/cct-dla/2022/1',
  '/progress/form002/cct-dla/2022/2',
  '/progress/covid19/cct-dla/2020/1',
  '/progress/covid19/cct-dla/2020/2',
  '/progress/covid19/cct-dla/2021/1',
  '/progress/covid19/cct-dla/2022/1',
  '/progress/support/cct-dla/2020/1',
  '/progress/support/cct-dla/2020/2',
  '/progress/support/cct-dla/2021/1',
  '/progress/support/cct-dla/2022/1',
  '/progress/support/cct-dla/2022/2',
  '/check/checkimg/cct-dla/2021/2',
  '/check/checkimg/cct-dla/2022/2',
  '/result/form01/cct-dla/2021/2',
  '/result/form01/cct-dla/2022/2',
  '/result/form01/cct-dla/2023/2',
  '/result/form05/cct-dla/2022/2',
  '/result/eef/cct-dla/2021/2',
  '/result/eef/cct-dla/2022/2',
  '/track/form01/cct-dla/2021/2',
  '/track/form01/cct-dla/2022/2',
  '/track/form05/cct-dla/2022/2',
  '/track/form06/cct-dla/2021/1',
  '/track/form06/cct-dla/2021/2',
  '/track/form06/cct-dla/2022/1',
  '/track/form06/cct-dla/2022/2',
  '/track/form001/cct-dla/2020/2',
  '/track/form001/cct-dla/2021/1',
  '/track/form001/cct-dla/2021/2',
  '/track/form001/cct-dla/2022/1',
  '/track/form001/cct-dla/2022/2',
  '/track/form002/cct-dla/2020/2',
  '/track/form002/cct-dla/2021/1',
  '/track/form002/cct-dla/2021/2',
  '/track/form002/cct-dla/2022/1',
  '/track/form002/cct-dla/2022/2',
  // bpp
  '/progress/form01/cct-bpp/2023/2',
  '/progress/form01/cct-bpp/2021/2',
  '/progress/form01/cct-bpp/2022/2',
  '/progress/form05/cct-bpp/2024/1/normal',
  '/progress/form05/cct-bpp/2022/2/normal',
  '/progress/form05/cct-bpp/2023/2/normal',
  '/progress/form06/cct-bpp/2021/1',
  '/progress/form06/cct-bpp/2021/2',
  '/progress/form06/cct-bpp/2022/1',
  '/progress/form06/cct-bpp/2022/2',
  '/progress/report/cct-bpp/2020/1',
  '/progress/report/cct-bpp/2020/2',
  '/progress/form001/cct-bpp/2020/2',
  '/progress/form001/cct-bpp/2021/1',
  '/progress/form001/cct-bpp/2021/2',
  '/progress/form001/cct-bpp/2022/1',
  '/progress/form001/cct-bpp/2022/2',
  '/progress/form002/cct-bpp/2020/2',
  '/progress/form002/cct-bpp/2021/1',
  '/progress/form002/cct-bpp/2021/2',
  '/progress/form002/cct-bpp/2022/1',
  '/progress/form002/cct-bpp/2022/2',
  '/progress/covid19/cct-bpp/2020/1',
  '/progress/covid19/cct-bpp/2020/2',
  '/progress/covid19/cct-bpp/2021/1',
  '/progress/covid19/cct-bpp/2022/1',
  '/progress/support/cct-bpp/2020/1',
  '/progress/support/cct-bpp/2020/2',
  '/progress/support/cct-bpp/2021/1',
  '/progress/support/cct-bpp/2022/1',
  '/progress/support/cct-bpp/2022/2',
  '/check/checkimg/cct-bpp/2021/2',
  '/check/checkimg/cct-bpp/2022/2',
  '/result/form01/cct-bpp/2021/2',
  '/result/form01/cct-bpp/2022/2',
  '/result/form01/cct-bpp/2023/2',
  '/result/form05/cct-bpp/2022/2',
  '/result/eef/cct-bpp/2021/2',
  '/result/eef/cct-bpp/2022/2',
  '/track/form01/cct-bpp/2021/2',
  '/track/form01/cct-bpp/2022/2',
  '/track/form05/cct-bpp/2022/2',
  '/track/form06/cct-bpp/2021/1',
  '/track/form06/cct-bpp/2021/2',
  '/track/form06/cct-bpp/2022/1',
  '/track/form06/cct-bpp/2022/2',
  '/track/form001/cct-bpp/2020/2',
  '/track/form001/cct-bpp/2021/1',
  '/track/form001/cct-bpp/2021/2',
  '/track/form001/cct-bpp/2022/1',
  '/track/form001/cct-bpp/2022/2',
  '/track/form002/cct-bpp/2020/2',
  '/track/form002/cct-bpp/2021/1',
  '/track/form002/cct-bpp/2021/2',
  '/track/form002/cct-bpp/2022/1',
  '/track/form002/cct-bpp/2022/2',
  // onab
  '/progress/spending/cct-onab/2023/2',
  '/progress/spending/cct-onab/2023/1',
  '/progress/spending/cct-onab/2022/2',
  '/progress/spending/cct-onab/2022/1',
  '/progress/spending/cct-onab/2021/2',
  '/progress/spending/cct-onab/2021/1',
  '/progress/spending/cct-onab/2020/2',
  '/progress/form002/cct-onab/2024/1',
  '/progress/form001/cct-onab/2024/1',
  '/progress/form06/cct-onab/2024/1',
  '/progress/form01/cct-onab/2023/2',
  '/progress/form01/cct-onab/2021/2',
  '/progress/form01/cct-onab/2022/2',
  '/progress/form05/cct-onab/2024/1/normal',
  '/progress/form05/cct-onab/2022/2/normal',
  '/progress/form05/cct-onab/2023/2/normal',
  '/progress/covid19/cct-onab/2020/1',
  '/progress/covid19/cct-onab/2020/2',
  '/progress/covid19/cct-onab/2021/1',
  '/progress/covid19/cct-onab/2022/1',
  '/check/checkimg/cct-onab/2021/2',
  '/check/checkimg/cct-onab/2022/2',
  '/result/form01/cct-onab/2021/2',
  '/result/form01/cct-onab/2022/2',
  '/result/form01/cct-onab/2023/2',
  '/result/form05/cct-onab/2022/2',
  '/result/eef/cct-onab/2021/2',
  '/result/eef/cct-onab/2022/2',
  '/track/form01/cct-onab/2021/2',
  '/track/form01/cct-onab/2022/2',
  '/track/form05/cct-onab/2022/2',
  // opec
  // '/progress/form01/cct-opec/2023/1',
  '/progress/form01/cct-opec/2020/1',
  '/progress/form01/cct-opec/2020/2',
  '/progress/form01/cct-opec/2021/2',
  '/progress/form01/cct-opec/2022/2',
  '/progress/form05/cct-opec/2024/1/normal',
  '/progress/form05/cct-opec/2020/1/normal',
  '/progress/form05/cct-opec/2020/2/normal',
  '/progress/form05/cct-opec/2021/1/normal',
  '/progress/form05/cct-opec/2022/2/normal',
  '/progress/report/cct-opec/2020/1',
  '/progress/report/cct-opec/2020/2',
  '/progress/report/cct-opec/2021/1',
  '/progress/covid19/cct-opec/2020/1',
  '/progress/covid19/cct-opec/2020/2',
  '/progress/covid19/cct-opec/2021/1',
  '/progress/covid19/cct-opec/2022/1',
  '/progress/attendance/cct-opec/2020/1',
  '/progress/attendance/cct-opec/2020/2',
  '/progress/attendance/cct-opec/2021/1',
  '/progress/wh/cct-opec/2020/1',
  '/progress/wh/cct-opec/2020/2',
  '/progress/wh/cct-opec/2021/1',
  '/progress/refund/cct-opec/2020/1',
  '/progress/refund/cct-opec/2020/2',
  '/progress/refund/cct-opec/2021/1',
  '/check/checkimg/cct-opec/2020/1',
  '/check/checkimg/cct-opec/2020/2',
  '/check/checkimg/cct-opec/2021/1',
  '/check/student/cct-opec/2020/1',
  '/check/student/cct-opec/2020/2',
  '/result/form01/cct-opec/2020/1',
  '/result/form01/cct-opec/2020/2',
  '/result/form01/cct-opec/2021/2',
  '/result/form01/cct-opec/2022/2',
  '/result/form01/cct-opec/2023/2',
  '/result/form05/cct-opec/2020/1',
  '/result/form05/cct-opec/2020/2',
  '/result/form05/cct-opec/2021/1',
  '/result/form05/cct-opec/2022/2',
  '/result/eef/cct-opec/2020/1',
  '/result/eef/cct-opec/2020/2',
  '/result/eef/cct-opec/2021/1',
  '/result/eef/cct-opec/2021/2',
  '/result/eef/cct-opec/2022/2',
  '/result/transfer/cct-opec/2020/1',
  '/result/transfer/cct-opec/2020/2',
  '/result/transfer/cct-opec/2021/1',
  '/result/transfer/cct-opec/2022/2',
  '/track/form01/cct-opec/2020/1',
  '/track/form01/cct-opec/2020/2',
  '/track/form01/cct-opec/2021/2',
  '/track/form01/cct-opec/2022/2',
  '/track/form05/cct-opec/2020/1',
  '/track/form05/cct-opec/2020/2',
  '/track/form05/cct-opec/2021/1',
  '/track/form05/cct-opec/2022/2',
  '/track/refund/cct-opec/2020/1',
  '/track/refund/cct-opec/2020/2',
  '/track/refund/cct-opec/2021/1',
  '/track/attend/cct-opec/2020/1',
  '/track/attend/cct-opec/2020/2',
  '/track/attend/cct-opec/2021/1',
  '/track/weight/cct-opec/2020/1',
  '/track/weight/cct-opec/2020/2',
  '/track/weight/cct-opec/2021/1',
  '/track/height/cct-opec/2020/1',
  '/track/height/cct-opec/2020/2',
  '/track/height/cct-opec/2021/1',
  '/track/form001/cct-opec/2020/2',
  '/track/form001/cct-opec/2021/1',
  '/track/form001/cct-opec/2021/2',
  '/track/form001/cct-opec/2022/1',
  '/track/form001/cct-opec/2022/2',
  '/track/form002/cct-opec/2020/2',
  '/track/form002/cct-opec/2021/1',
  '/track/form002/cct-opec/2021/2',
  '/track/form002/cct-opec/2022/1',
  '/track/form002/cct-opec/2022/2',
  // bkk
  '/progress/covid19/cct-bkk/2023/1',
  '/progress/covid19/cct-bkk/2022/2',
  '/progress/covid19/cct-bkk/2022/1',
  '/progress/covid19/cct-bkk/2021/2',
  '/progress/covid19/cct-bkk/2021/1',
  '/progress/form01/cct-bkk/2023/2',
  '/progress/form01/cct-bkk/2020/1',
  '/progress/form01/cct-bkk/2020/2',
  '/progress/form01/cct-bkk/2021/1',
  '/progress/form01/cct-bkk/2021/2',
  '/progress/form01/cct-bkk/2022/2',
  '/progress/form05/cct-bkk/2024/1/normal',
  '/progress/form05/cct-bkk/2020/1/normal',
  '/progress/form05/cct-bkk/2020/2/normal',
  '/progress/form05/cct-bkk/2021/1/normal',
  '/progress/form05/cct-bkk/2021/2/normal',
  '/progress/form05/cct-bkk/2022/1/normal',
  '/progress/report/cct-bkk/2020/1',
  '/progress/report/cct-bkk/2020/2',
  '/progress/report/cct-bkk/2021/1',
  '/progress/report/cct-bkk/2021/2',
  '/progress/report/cct-bkk/2022/1',
  '/progress/attendance/cct-bkk/2020/1',
  '/progress/attendance/cct-bkk/2020/2',
  '/progress/attendance/cct-bkk/2021/1',
  '/progress/attendance/cct-bkk/2021/2',
  '/progress/attendance/cct-bkk/2022/1',
  '/progress/wh/cct-bkk/2020/1',
  '/progress/wh/cct-bkk/2020/2',
  '/progress/wh/cct-bkk/2021/1',
  '/progress/wh/cct-bkk/2021/2',
  '/progress/wh/cct-bkk/2022/1',
  '/progress/refund/cct-bkk/2020/1',
  '/progress/refund/cct-bkk/2020/2',
  '/progress/refund/cct-bkk/2021/1',
  '/progress/refund/cct-bkk/2021/2',
  '/progress/refund/cct-bkk/2022/1',
  '/check/checkimg/cct-bkk/2020/1',
  '/check/checkimg/cct-bkk/2020/2',
  '/check/checkimg/cct-bkk/2021/1',
  '/check/checkimg/cct-bkk/2021/2',
  '/check/checkimg/cct-bkk/2022/2',
  '/check/student/cct-bkk/2020/1',
  '/check/student/cct-bkk/2020/2',
  '/check/student/cct-bkk/2021/1',
  '/result/form01/cct-bkk/2020/1',
  '/result/form01/cct-bkk/2020/2',
  '/result/form01/cct-bkk/2021/1',
  '/result/form01/cct-bkk/2021/2',
  '/result/form01/cct-bkk/2022/2',
  '/result/form05/cct-bkk/2020/1',
  '/result/form05/cct-bkk/2020/2',
  '/result/form05/cct-bkk/2021/1',
  '/result/form05/cct-bkk/2021/2',
  '/result/form05/cct-bkk/2022/1',
  '/result/eef/cct-bkk/2020/1',
  '/result/eef/cct-bkk/2020/2',
  '/result/eef/cct-bkk/2021/1',
  '/result/eef/cct-bkk/2021/2',
  '/result/eef/cct-bkk/2022/2',
  '/result/transfer/cct-bkk/2020/1',
  '/result/transfer/cct-bkk/2020/2',
  '/result/transfer/cct-bkk/2021/1',
  '/result/transfer/cct-bkk/2021/2',
  '/result/transfer/cct-bkk/2022/2',
  '/track/form01/cct-bkk/2020/1',
  '/track/form01/cct-bkk/2020/2',
  '/track/form01/cct-bkk/2021/1',
  '/track/form01/cct-bkk/2021/2',
  '/track/form01/cct-bkk/2022/2',
  '/track/form05/cct-bkk/2020/1',
  '/track/form05/cct-bkk/2020/2',
  '/track/form05/cct-bkk/2021/1',
  '/track/form05/cct-bkk/2021/2',
  '/track/form05/cct-bkk/2022/1',
  '/track/refund/cct-bkk/2020/1',
  '/track/refund/cct-bkk/2020/2',
  '/track/refund/cct-bkk/2021/1',
  '/track/refund/cct-bkk/2021/2',
  '/track/refund/cct-bkk/2022/1',
  '/track/attend/cct-bkk/2020/1',
  '/track/attend/cct-bkk/2020/2',
  '/track/attend/cct-bkk/2021/1',
  '/track/attend/cct-bkk/2021/2',
  '/track/attend/cct-bkk/2022/1',
  '/track/weight/cct-bkk/2020/1',
  '/track/weight/cct-bkk/2020/2',
  '/track/weight/cct-bkk/2021/1',
  '/track/height/cct-bkk/2020/1',
  '/track/height/cct-bkk/2020/2',
  '/track/height/cct-bkk/2021/1',
  '/track/height/cct-bkk/2021/2',
  '/track/height/cct-bkk/2022/1',
]

module.exports = {
  duration,
  YEAR_SEMESTER,
  MONTH,
  CURRENT_YEAR,
  CURRENT_SEMESTER,
  SYSTEMS,
  OBEC_OPEN_CLASS,
  ROLES,
  EXCLUDE_SIDEBAR_MENU,
  EXCLUDE_MENU_YEAR,
}
