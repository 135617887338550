import { useReducer, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import Image from 'next/image'
import AppContext from '../components/AppContext'
import NProgress from 'nprogress'
import Login from './login'
import 'nprogress/nprogress.css'
// import 'antd/dist/antd.css'
import './app.css'
import { Layout, Menu, Button, Popover } from 'antd'
import { CURRENT_YEAR, CURRENT_SEMESTER, ROLES } from '../utils/config'
import Script from 'next/script'
import { ConfigProvider } from "antd";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

const cache = createCache({ key: 'css', nonce: process.env.nonce });
const { Header, Content, Footer } = Layout
console.log('app v.1.0.0.15')

Number.prototype.formatMoney = function (c, d, t) {
  var n = this,
    c = isNaN((c = Math.abs(c))) ? 2 : c,
    d = d == undefined ? '.' : d,
    t = t == undefined ? ',' : t,
    s = n < 0 ? '-' : '',
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (j = i.length) > 3 ? j % 3 : 0
  return (
    s +
    (j ? i.substr(0, j) + t : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
    (c
      ? d +
      Math.abs(n - i)
        .toFixed(c)
        .slice(2)
      : '')
  )
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'restore':
      return { ...action.value }
    case 'set_user':
      return { ...state, user: action.value }
    case 'change_path':
      return { ...state, path: action.path }
    case 'change_system':
      return { ...state, system: action.value }
    case 'change_year':
      return {
        ...state,
        ...{
          year: action.value?.year,
          semester: action.value?.semester,
        },
      }
    default:
      return { ...state }
  }
}

const paths = {
  '/': 'หน้าหลัก',
  [`/progress/form01/cct/${CURRENT_YEAR}/${CURRENT_SEMESTER}`]: 'ความก้าวหน้า',
  [`/check/checkimg/cct/${CURRENT_YEAR}/${CURRENT_SEMESTER}`]: 'ตรวจสอบข้อมูล',
  [`/result/form01/cct/${CURRENT_YEAR}/${CURRENT_SEMESTER}`]: 'ผลการดำเนินงาน',
  [`/track/form01/cct/${CURRENT_YEAR}/${CURRENT_SEMESTER}`]: 'ติดตามเร่งด่วน',
  // [`/searchstudent/${CURRENT_YEAR}/${CURRENT_SEMESTER}`]: 'ค้นหา นร.',
  [`/searchstudent/cct/${CURRENT_YEAR}/${CURRENT_SEMESTER}`]: 'ค้นหา นร.',
  '/searchhost/null': 'ค้นหา รร.',
  '/contact/area/cct': 'ข้อมูลติดต่อ',
}
const imageMenu = {
  หน้าหลัก: '/images/headers/home.png',
  ความก้าวหน้า: '/images/headers/progress.png',
  ตรวจสอบข้อมูล: '/images/check.jpg',
  ผลการดำเนินงาน: '/images/headers/result.png',
  ติดตามเร่งด่วน: '/images/headers/track.png',
  'ค้นหา นร.': '/images/headers/searchstudent.png',
  'ค้นหา รร.': '/images/headers/searchhost.png',
  ข้อมูลติดต่อ: '/images/headers/contact.png',
}

export default function MyApp({ Component, pageProps }) {
  const [visible, setVisible] = useState(false)
  const [state, dispatch] = useReducer(reducer, {
    user: null,
    system: 'cct',
    year: 2020,
    semester: 1,
  })
  const router = useRouter()

  useEffect(() => {
    // cookie
    CookieConsent.run({
      categories: {
        necessary: {
          enabled: true,  // this category is enabled by default
          readOnly: true  // this category cannot be disabled
        },
        analytics: {}
      },

      current_lang: 'th',
      autoclear_cookies: true,           // default: false
      page_scripts: true,

      language: {
        default: 'th',
        translations: {
          en: {
            consentModal: {
              // title: 'We use cookies',
              description: `เว็บไซต์นี้มีการใช้งานคุกกี้ (Cookies) เพื่อประสบการณ์การใช้งานเว็บไซต์ที่ดียิ่งขึ้น
              รวมถึงนำเสนอเนื้อหาที่ตรงตามความสนใจของท่านมากที่สุด ทั้งนี้ ท่านสามารถศึกษารายละเอียดเกี่ยวกับการใช้คุกกี้ ได้ที่
              <button type="button" data-cc="c-settings" class="cc-link">นโยบายการใช้คุกกี้</button>`,
              acceptAllBtn: 'ยอมรับ',
              // acceptNecessaryBtn: 'Reject all',
              // showPreferencesBtn: 'Manage Individual preferences'
            },
            settings_modal: {
              title: 'นโยบายการใช้คุกกี้',
              // save_settings_btn: 'บันทึกข้อมูล',
              accept_all_btn: 'ยอมรับ',
              // reject_all_btn: 'ปฏิเสธทั้งหมด',
              close_btn_label: 'ปิด',
              cookie_table_headers: [
                { col1: 'Name' },
                { col2: 'Domain' },
                { col3: 'Expiration' },
                { col4: 'Description' }
              ],
              blocks: [
                {
                  //title: 'นโยบายการใช้คุกกี้',
                  description: `เมื่อท่านได้เข้าสู่เว็บไซต์ ${window.location.origin} 
                  ข้อมูลที่เกี่ยวข้องกับการเข้าสู่เว็บไซต์ ของท่านจะถูกบันทึกไว้ในรูปแบบของคุกกี้ โดยนโยบายคุกกี้นี้จะอธิบายถึงความหมาย
                  การทำงาน วัตถุประสงค์ รวมถึงการลบและการปฏิเสธการเก็บคุกกี้ เพื่อความเป็นส่วนตัวของท่าน
                  โดยการเข้าสู่เว็บไซต์นี้ถือว่าท่านได้อนุญาตให้เราใช้คุกกี้ตามนโยบายคุกกี้ที่มีรายละเอียด ดังต่อไปนี้`
                }, {
                  title: "คุกกี้คืออะไร?",
                  description: `คุกกี้ คือ ไฟล์ขนาดเล็กเพื่อจัดเก็บข้อมูล โดยจะบันทึกลงในอุปกรณ์คอมพิวเตอร์ และ/หรือเครื่องมือสื่อสารที่เข้าใช้งานของท่าน 
                  เช่น สมาร์ทโฟน แท็บเล็ต เป็นต้น ผ่านทางเว็บบราวน์เซอร์ในขณะที่ท่านเข้าสู่เว็บไซต์ของเรา โดยคุกกี้จะไม่ก่อให้เกิดอันตรายต่ออุปกรณ์คอมพิวเตอร์ 
                  และ/หรือเครื่องมือสื่อสารของท่านในกรณีนี้ ข้อมูลส่วนบุคคลของท่านจะถูกจัดเก็บ เพื่อใช้เพิ่มประสบการณ์การใช้งานบริการของเราทางออนไลน์ 
                  โดยจะจำเอกลักษณ์ของภาษาและปรับแต่งข้อมูลการใช้งานตามความต้องการของท่าน โดยการเก็บข้อมูลนี้เพื่อเป็นการยืนยันคุณลักษณะเฉพาะตัว 
                  ข้อมูลความปลอดภัยของท่าน รวมถึงผลิตภัณฑ์และบริการที่ท่านสนใจ นอกจากนี้ คุกกี้ยังถูกใช้เพื่อวัดปริมาณการเข้าใช้งานบริการทางออนไลน์ 
                  การปรับเปลี่ยนเนื้อหาตามการใช้งานของท่านทั้งในก่อนหน้าและปัจจุบัน หรือเพื่อวัตถุประสงค์ในการโฆษณาและประชาสัมพันธ์`
                }, {
                  title: "เราใช้คุกกี้อย่างไร",
                  description: `เราใช้คุกกี้เพื่อเพิ่มประสบการณ์และความพึงพอใจในการใช้บริการเว็บไซต์ของท่าน ซึ่งรวมถึงการให้บริการฟังก์ชันพื้นฐาน 
                  การจดจำการเข้าสู่ระบบของท่าน จดจำลักษณะการใช้งานเว็บไซต์ พฤติกรรม ความชื่นชอบ และความสนใจของท่าน ซึ่งทำให้เว็บไซต์ของเราเข้าถึงได้ง่ายและสะดวกยิ่งขึ้น 
                  ในบางกรณีเราจำเป็นต้องให้บุคคลที่สามดำเนินการ ซึ่งอาจต้องใช้ IP Address และคุกกี้เพื่อการวิเคราะห์ทางสถิติ รวมทั้งเชื่อมโยงข้อมูล 
                  และประมวลผลตามวัตถุประสงค์ทางการตลาด โดย กสศ. ใช้คุกกี้ประเภทต่าง ๆ ดังต่อไปนี้`
                }, {
                  title: 'คุกกี้ที่มีความจำเป็น (Necessary Cookies)',
                  description: `คุกกี้เหล่านี้ถูกใช้บนเว็บไซต์ของเราเพื่อระบุสถานะการแสดงผลบนเว็บไซต์ของเรา เช่น คุกกี้ที่แจ้งให้เราทราบว่าท่านได้สร้างบัญชีและลงชื่อเข้าใช้บัญชีนั้น ๆ ทั้งนี้ คุกกี้ประเภทนี้จะไม่ระบุตัวบุคคลของท่าน`,
                  toggle: {
                    value: 'necessary',
                    enabled: true,
                    readonly: true // cookie categories with readonly=true are all treated as "necessary cookies"
                  }
                }, {
                  title: 'คุกกี้เพื่อการวิเคราะห์/ประสิทธิภาพ (Performance Cookies)',
                  description: `คุกกี้เหล่านี้ถูกใช้บนเว็บไซต์ของเราเพื่อรับทราบและเก็บรวบรวมค่าสถิติและพฤติกรรมการเยี่ยมชมเว็บไซต์ โดยให้ข้อมูลเกี่ยวกับวิธีที่ผู้ใช้โต้ตอบกับเว็บไซต์ เช่น จดจำการตั้งค่าของท่าน จำนวนการเข้าชมเว็บไซด์ต่อหน้า เวลาที่ใช้บนหน้าเว็บ คุกกี้ประเภทนี้จะช่วยให้เราสามารถปรับปรุงเว็บไซต์ของเราให้มีประสิทธิภาพยิ่งขึ้น ทั้งนี้ คุกกี้ประเภทนี้จะไม่ระบุตัวบุคคลของท่าน`,
                  toggle: {
                    value: 'analytics', // your cookie category
                    enabled: true,
                    readonly: true
                  }
                }, {
                  title: "ท่านจะจัดการคุกกี้อย่างไร",
                  description: `ท่านสามารถจัดการเก็บคุกกี้ต่าง ๆ ได้ โดยศึกษาตามวิธีการที่ระบุในแต่ละเว็บบราวน์เซอร์ที่ท่านใช้อยู่ เช่น
                    <ul style="padding-left:20px;padding-top:10px">
                      <li style="padding-left:10px;padding-bottom:10px"><strong>Internet Explorer</strong> 
                        <ul>
                          <li>
                            <a href="http://windows.microsoft.com/en-GB/internet-explorer/delete-manage-cookies" 
                              title="http://windows.microsoft.com/en-GB/internet-explorer/delete-manage-cookies" 
                              target="_blank" rel="noreferrer noopener">
                              ป้องกันไม่ให้มีการติดตั้งคุกกี้ใหม่และลบคุกกี้ที่มีอยู่
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li style="padding-left:10px;padding-bottom:10px"><strong>Firefox</strong> 
                        <ul>
                          <li>
                            <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" 
                              title="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" 
                              target="_blank" rel="noreferrer noopener">
                                ป้องกันการติดตั้งคุกกี้ใหม่
                            </a>
                          </li>
                          <li>
                            <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored" 
                              target="_blank" rel="noreferrer noopener" 
                              title="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">
                                วิธีลบคุกกี้ที่มีอยู่
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li style="padding-left:10px;padding-bottom:10px"><strong>Google Chrome</strong>
                        <ul>
                          <li>
                            <a href="https://support.google.com/chrome/answer/95647?hl=th" 
                              title="https://support.google.com/chrome/answer/95647?hl=th" 
                              target="_blank" rel="noreferrer noopener">
                                ป้องกันไม่ให้มีการติดตั้งคุกกี้ใหม่และลบคุกกี้ที่มีอยู่
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li style="padding-left:10px;padding-bottom:10px"><strong>Safari</strong>
                        <ul>
                          <li>
                            <a href="http://help.apple.com/safari/mac/8.0/#/sfri11471" 
                              target="_blank" rel="noreferrer noopener" class="__mPS2id">
                                ป้องกันไม่ให้มีการติดตั้งคุกกี้ใหม่และลบคุกกี้ที่มีอยู่
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li style="padding-left:10px;padding-bottom:10px"><strong>Microsoft Edge</strong>
                        <ul>
                          <li>
                            <a href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09" 
                              target="_blank" rel="noreferrer noopener" title="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">
                                ป้องกันไม่ให้มีการติดตั้งคุกกี้ใหม่และลบคุกกี้ที่มีอยู่
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>`
                }, {
                  title: "วิธีการจัดเก็บ",
                  description: `<p>เราจะจัดเก็บข้อมูลของท่านที่ได้มาจากคุกกี้ ดังนี้:</p>
                    <ol style="padding-left:20px;padding-top:10px">
                      <li>ลักษณะการจัดเก็บ:<strong> </strong>
                        เราเก็บข้อมูลของท่านด้วยวิธีทางอิเล็กทรอนิกส์
                      </li>
                      <li>สถานที่จัดเก็บ:<strong> </strong>
                        เราเก็บไว้ในคลาวด์เซิร์ฟเวอร์
                      </li>
                      <li>ระยะเวลาการจัดเก็บ:<strong> </strong>
                        โปรดดู<strong> </strong>“การตั้งค่า”
                      </li>
                      <li>เมื่อระยะเวลาการจัดเก็บสิ้นสุดลง เราจะลบข้อมูลเหล่านี้จากเซิร์ฟเวอร์ของเรารวมถึงพื้นที่จัดเก็บแบบคลาวด์ภายในระยะเวลาตามตารางในหัวข้อ 
                          “การตั้งค่า” หากท่านต้องการศึกษาข้อมูลเพิ่มเติมเกี่ยวกับคุกกี้หรือวิธีการดูการกำหนดค่าคุกกี้ สามารถดูได้ที่ 
                        <a href="http://www.aboutcookies.org" target="_blank" rel="noreferrer noopener" title="www.aboutcookies.org">
                          www.aboutcookies.org
                        </a> หรือ 
                        <a href="http://www.allaboutcookies.org" target="_blank" rel="noreferrer noopener" title="www.allaboutcookies.org">
                          www.allaboutcookies.org
                        </a>
                      </li>
                    </ol>`
                }, {
                  title: "วิธีการจัดเก็บ",
                  description: `<p>นโยบายคุกกี้นี้อาจมีการปรับปรุงแก้ไขตามความเหมาะสม เพื่อให้สอดคล้องตามกฎหมายและกฎระเบียบ ทั้งนี้ 
                    ท่านสามารถดูข้อมูลเพิ่มเติมเกี่ยวกับการประมวลผลข้อมูลส่วนบุคคล สิทธิของท่าน และช่องทางการติดต่อ ผู้ควบคุมข้อมูลได้ที่ 
                    <a href="https://www.eef.or.th/privacy-policy" target="_blank" title="https://www.eef.or.th/privacy-policy">
                    นโยบายคุ้มครองข้อมูลส่วนบุคคล</a></p>`
                }
              ]
            }
          }
        }
      }
    });

    // state
    let state = sessionStorage.getItem('state')
    if (state) {
      dispatch({
        type: 'restore',
        value: JSON.parse(state),
      })
    }

    // router
    NProgress.configure({ minimum: 0.2 })
    let routeChangeStart = () => NProgress.start()
    let routeChangeComplete = () => NProgress.done()
    router.events.on('routeChangeStart', routeChangeStart)
    router.events.on('routeChangeComplete', routeChangeComplete)
    router.events.on('routeChangeError', routeChangeComplete)
    return () => {
      router.events.off('routeChangeStart', routeChangeStart)
      router.events.off('routeChangeComplete', routeChangeComplete)
      router.events.off('routeChangeError', routeChangeComplete)
    }
  }, [])

  useEffect(() => {
    sessionStorage.setItem('state', JSON.stringify(state))
  }, [state])

  useEffect(() => {
    if (!state?.user) return;
    const {
      user: {
        user: { roles: userRoles },
      },
    } = state

    if (userRoles?.includes('staff_tu')) router.push(`${ROLES['staff_tu'][0]}/cct/${CURRENT_YEAR}/${CURRENT_SEMESTER}`)
  }, [state])

  const findMenu = () => {
    for (let key in paths) {
      if (key === '/') continue
      let menu = key.split('/')[1]
      if (router.pathname.startsWith(`/${menu}`)) {
        return key
      }
    }
    return '/'
  }

  const onLogout = () => {
    setVisible(false)
    dispatch({
      type: 'set_user',
      value: null,
    })
  }

  return <CacheProvider value={cache}>
    <ConfigProvider
      csp={{ nonce: process.env.nonce }}
      theme={{
        token: {
          fontFamily: "Mitr"
        }
      }}>
      {!state.user ? (
        <AppContext.Provider value={[state, dispatch]}>
          <Login />
        </AppContext.Provider>
      ) : (
        <Layout style={{ minHeight: '100vh' }}>
          <Script
            src="https://www.googletagmanager.com/gtag/js?id=G-4KZXF48NR8"
            strategy="afterInteractive"
          />
          <Script id="google-analytics" strategy="afterInteractive">
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'G-4KZXF48NR8');
        `}
          </Script>
          <Header style={{ background: '#fff', padding: 0 }}>
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: 160,
                height: 65,
                backgroundColor: 'black',
                paddingTop: 8,
              }}
            >
              <Image src="/images/logo.png" alt="logo" width="150" height="50" />
            </div>
            <Menu
              theme="light"
              mode="horizontal"
              selectedKeys={[findMenu()]}
              style={{ marginLeft: '160px', marginRight: 150 }}
            >
              {Object.keys(paths)
                .filter((x) => {
                  const {
                    user: {
                      user: { roles: userRoles },
                    },
                  } = state
                  const [, mainMenu] = x.split('/')
                  if (userRoles.includes('eef')) return true
                  if (userRoles.includes('nu')) return true
                  if (userRoles.includes('staff_tu') && !mainMenu) return false
                  const checkExistsPathRole = userRoles.some((a) =>
                    (ROLES[a] || []).some((r) => r.includes(mainMenu)),
                  )
                  return checkExistsPathRole
                })
                .map((key, i) => (
                  <Menu.Item
                    key={key}
                    style={{ textAlign: 'center', height: 60, minWidth: 100 }}
                  >
                    <Link href={key}>
                      <div style={{ marginTop: -10 }} className="menuIcon">
                        <Image
                          src={imageMenu[paths[key]]}
                          alt={'logo' + i}
                          width="20"
                          height="20"
                        />
                        <div style={{ marginTop: -40 }}>{paths[key]}</div>
                      </div>
                    </Link>
                  </Menu.Item>
                ))}
            </Menu>
            <div
              style={{
                position: 'absolute',
                top: 10,
                right: 10,
              }}
            >
              <Popover
                content={<a onClick={onLogout}>logout</a>}
                title=""
                trigger="click"
                visible={visible}
                onVisibleChange={(visible) => setVisible(visible)}
              >
                <Button
                  shape="round"
                  size={'large'}
                  style={{ display: 'flex', width: 150 }}
                >
                  <span style={{ flex: 1 }}>{state?.user?.user?.user}</span>
                  <span style={{ marginTop: -2, marginRight: -14 }}>
                    <Image
                      src="/images/headers/user.png"
                      alt="avatar"
                      width="30"
                      height="30"
                    />
                  </span>
                </Button>
              </Popover>
            </div>
          </Header>
          <Content style={{ margin: '24px 16px 0' }}>
            <AppContext.Provider value={[state, dispatch]}>
              <Component {...pageProps} />
            </AppContext.Provider>
          </Content>
          <Footer style={{ textAlign: 'center' }}>@CCT Monitor</Footer>
        </Layout>
      )}
    </ConfigProvider>
  </CacheProvider>
}
